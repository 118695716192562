<template>
    <div>
        <el-row>
            <el-form v-model="params" :inline="true" label-position="right" label-width="110px" size="mini">
                <el-form-item label="班主任名称" prop="teacherId">
                    <el-select v-model="params.teacherId" filterable>
                        <el-option v-for="item in accountList" :key="item.id" :label="item.nickName"
                                   :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="时间区间" prop="buyTime">
                    <el-date-picker
                            v-model="todayTime"
                            type="daterange"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" size="mini" @click="doSearch">搜索</el-button>
                    <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
                </el-form-item>
            </el-form>
        </el-row>
        <el-row>
            <el-col :span="18">
                <el-button type="primary" icon="el-icon-download" v-permission="'waiter:analysis:download'" size="mini"
                           @click="download">导出
                </el-button>
            </el-col>
            <el-col :span="6">

            </el-col>
        </el-row>
        <el-table :data="dataList" stripe fit max-height="640" v-loading="loading">
            <el-table-column align="center" label="班主任名称" prop="teacherId">
                <template v-slot="scope">
                    <span v-for="item in accountList" :key="item.id"
                          v-if="item.id === scope.row.teacherId">{{ item.nickName }}</span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="领取学员数" prop="receive"></el-table-column>
            <el-table-column align="center" label="沟通学员数" prop="chat"></el-table-column>
            <el-table-column align="center" label="回访数" prop="access"></el-table-column>
            <el-table-column align="center" label="查看学员数" prop="view"></el-table-column>
        </el-table>

        <el-pagination
                @size-change="findPage"
                @current-change="findPage"
                :current-page.sync="params.current"
                :page-sizes="[10, 20, 30, 40]"
                :page-size.sync="params.size"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
        </el-pagination>
    </div>
</template>

<script>
import * as analysisApi from "@/api/waiter/waiterDataAnalysis.js"
import * as accountApi from "@/api/system/account";
import axios from "axios";

export default {
    name: "WaiterReceive",
    data() {
        return {
            todayTime: [],
            params: {
                current: 1,
                size: 10
            },
            loading: false,
            dataList: [],
            total: 0,
            accountList: [],
        }
    },
    mounted() {
        this.resetQuery()
        this.findPage()
        this.findAccount()
    },
    methods: {
        formatTimes(date) {
            // 获取各种类型当前时间
            let year = date.getFullYear();    // 获取年
            let month = date.getMonth() + 1;   //获取当前月
            month = month < 10 ? '0' + month : month
            let day = date.getDate();   //日
            day = day < 10 ? '0' + day : day
            return year + "-" + month + "-" + day
        },
        findAccount() {
            accountApi.list().then(res => {
                if (res.success) {
                    this.accountList = res.data
                }
            })
        },
        resetQuery() {
            this.todayTime = [this.formatTimes(new Date()), this.formatTimes(new Date())]
            this.params = {
                current: 1,
                size: 10
            }
        },
        doSearch() {
            this.params.current = 1
            this.findPage()
        },
        download() {
            if (this.todayTime.length > 0) {
                this.params.startDay = this.todayTime[0]
                this.params.endDay = this.todayTime[1]
            }
            axios({
                method: "get",
                url: '/api/manage/waiter/data/analysis/download',
                headers: {
                    "version": localStorage.getItem("_version"),
                },
                responseType: 'blob',
                params: this.params
            }).then(res => {
                const blob = new Blob([res.data]);
                const fileName = this.params.startDay + '-' + this.params.endDay + '数据统计.xlsx';
                const elink = document.createElement('a')
                elink.download = fileName;
                elink.style.display = 'none';
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
            }).catch(error => {
                this.$message.error("导出数据统计失败");
            })
        },
        findPage() {
            this.loading = true
            if (this.todayTime.length > 0) {
                this.params.startDay = this.todayTime[0]
                this.params.endDay = this.todayTime[1]
            }
            analysisApi.findPage(this.params).then(res => {
                this.loading = false
                if (res.success) {
                    this.dataList = res.data.records
                    this.total = res.data.total
                }
            }).catch(e => {
                this.$message.error("系统异常,请稍后重试:" + e)
                this.loading = false
            })
        },
    }
}
</script>

<style scoped>
.el-pagination {
    bottom: 10px;
    position: fixed;
}
</style>